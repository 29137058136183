.topbarhome{
  background: transparent;
}
.home-new {
  background-color: #030821;
  // section-banner
  .section-banner{
    width: 100%;
  }
  // section-notice
  .section-notice{
    padding: 20px 3.5%;
    .wrapper{
    border: 2px solid #7f818e;
    border-radius: 5px;
    background-color: #020411;
    display: flex;
    box-sizing: border-box;
    padding: 3px 12px;
      .notice-img{
        width: 25px;
        margin-right: 10px;
      }
      .notice-swiper{
        width: calc(100% - 35px);
      }
      .ellipsis {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
        color: #fff;
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
  // section-about
  .section-about{
    width: 100%;
    .con-box{
      padding: 0 3.5%;

    }
    .title{
      font-size: .3rem;
      font-family: "Lantinghei SC";
      color: rgb(105, 102, 255);
      font-weight: bold;
      line-height: 1.5;
    }
    .content{
      font-size: 12px;
        color: rgb(240, 242, 234);
        line-height: 1.429;
        padding: .1rem 0 .3rem;
    }
  }
  // section-introduce
  .section-introduce{
    padding: 0 2%;
    overflow-x: auto;
    .list{
      padding: .3rem 0;
      margin: 0;
      display: flex;
      width: 170vw;
      li.item{
        width:calc(33.3% - .1rem);
        padding: .2rem;
        margin: 0 .1rem 0 0;
        background: linear-gradient(to bottom, #0d1838, #0d1838,#0d18381f);
        .title{
          font-size: .22rem;
          color: #6c69ff;
        }
        .content{
          font-size: 12px;
          color: rgb(240, 242, 234);
          line-height: 1.429;
          padding: .1rem 0;
        }
      }
    }
  }
  // section-person
  .section-person{
    .title{

      font-size: .36rem;
      font-family: "Lantinghei SC";
      color: rgb(105, 102, 255);
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      letter-spacing: 2px;
      position: relative;
      text-transform: uppercase;
      margin-bottom: -2rem;
      span{
        display: inline;
        font-size: .5rem;
      }
    }
    .con{
      width: 100%;
      overflow-y: auto;
      img{
        display: block;
        margin: auto;
        max-width: 500vw;
        width: 195vw;
      }
    }
  }
  // section-model
  .section-model{
    padding: 0 3.5%;
    .title{
      font-size: .36rem;
      font-family: "Lantinghei SC";
      color: rgb(105, 102, 255);
      font-weight: bold;
      line-height: 1.5;
    }
    .tips{
      font-size: .28rem;
background: #223b6b;
line-height: 1.5;
color: #fff;
padding: .2rem .1rem .1rem;
    }
    .content{

      font-size: 12px;
        color: rgb(240, 242, 234);
        line-height: 1.429;
        padding: .1rem 0 .15rem;
    }
    .picture{
width: 100%;
padding: 0;
    }
    .list{
      display: flex;
      margin: .1rem 0;
      padding: 0;
      list-style-type: none;
      list-style: none;

      li{
        width: 32%;
        margin: .1rem 1.5%;
        font-weight: bold;
        padding: .1rem;
        background-color: #122044;
        color:#babcc8 ;
        text-align: center;
        list-style-type: none;
        list-style: none;
        display: flex;

  -webkit-box-pack: justify;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
        p{
          display: flex;

          -webkit-box-align: center;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
        
          -webkit-box-pack: center;
          -moz-justify-content: center;
          -webkit-justify-content: center;
          justify-content: center;
          width: 100%;
          height: 70%;
          padding: .15rem;
          font-size: .22rem;
          margin: 0;
          line-height: 1.25;
          span{
            display: block;
          }
        }
      .no{
        border-top: 4px solid #000000;
display: block;
height: auto;
padding: 0.15rem .15rem .07rem;
      }

      }
    }
  }
  // section-last
  .section-last{
    position: relative;
    z-index: 2;
    .bg{
      position: relative;
      z-index: 2;
      width: 100%;
    }
    .section4-right{
      position: absolute;
      z-index: 4;
      top: 0.45rem;
      right: 0;
      width: 35%;
    }
    .con-box{
      position: absolute;
      width: 100%;
      top:0.8rem;
      z-index: 3;
      .border-box{
        border: 2px solid rgba(255,255,255,.36);
        background-color: rgba(0,0,0,.36);
        padding: .25rem .1rem;
        font-size: 12px;
        color: rgb(240, 242, 234);
        line-height: 1.429;
        max-width: 50%;
        margin: auto;
      }
    }  // 底部
    .footer {
      padding-left: .4rem;
      padding-right: .4rem;
      width: 100%;
      height: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 4;
      div {
        width: 0.8rem;
        height: 0.8rem;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      .twitter {
        background: url("../../assets2/home/twitter.png") no-repeat center;
        background-size: 100% 100%;
      }
      .ter {
        background: url("../../assets2/home/ter.png") no-repeat center;
        background-size: 100% 100%;
      }
      .youxi {
        background: url("../../assets2/home/youxi.png") no-repeat center;
        background-size: 100% 100%;
      }
      .medium {
        background: url("../../assets2/home/medium.png") no-repeat center;
        background-size: 100% 100%;
      }
      .xin {
        background: url("../../assets2/home/xin.png") no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
}
@primary-color:  #961315;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);
@import '~antd/dist/antd.css';
html {
  font-size: 625%;
  box-sizing: border-box;
}

div {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

@media screen and (max-width: 1440px){
  html{font-size: 463.8% !important;}
}
@media screen and (max-width: 800px){
  html{font-size: 300% !important;}
}
@media screen and (max-width: 750px){
  html{font-size: 625% !important;}
}
@media screen and (max-width: 500px){
  html{font-size: 400% !important;}
}
@media screen and (max-width: 414px){
  html{font-size: 345% !important;}
}
@media screen and (max-width: 375px){
  html{font-size: 312.5% !important;}
}

body {
  margin: 0;
  padding: 0;
  font-family: PingFang SC, PingFang SC-Medium;
  font-size: .14rem;
  box-sizing: border-box;
  background-color: #000 !important;

}
p{
  margin: 0;
  padding: 0;
}

.ant-message{
  margin-top: 1rem !important;
}

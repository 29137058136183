.mining {
  width: 100%;
  position: absolute;
  top: 0;
  padding-top: 0.8rem;
  min-height: 100vh;
  // 背景
  .miningbg_bg {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    opacity: 1;
  }

  .mining_top {
    position: absolute;
    width: 100%;
    height: 150px;
    padding: 0.3rem;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    .top_img {
      width: 2rem;
      > img {
        width: 1.5rem;
      }
    }
    .tab_title {
      // width: 3.4rem;
      width: 1.7rem;
      display: flex;
      .tab_lp {
        display: flex;
        align-items: center;
        width: 1.6rem;
        height: 0.8rem;
        margin-right: 0.1rem;
        > img {
          width: 100%;
          height: 100%;
        }
        .tab_img {
          height: 60%;
        }
      }
      .tab_sc {
        display: flex;
        align-items: center;
        width: 1.6rem;
        height: 0.8rem;
        > img {
          width: 100%;
          height: 100%;
        }
        .tab_img {
          height: 60%;
        }
      }
    }
  }

  // 内容
  .miningbg_con {
    position: relative;
    width: 100%;
    margin: 0 auto;
    .mining_tab {
      width: 100%;
      position: relative;
      .ant-tabs-nav {
        width: 100%;
        height: 300px;
        padding: 0;
        background: url("../../assets2/mining/minibg.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 0 !important;
      }
      .ant-tabs-content-holder {
        background-image: linear-gradient(#131769, #671789);
        padding: 0 0.3rem;
      }
      // tab未选中
      .ant-tabs-tab {
        height: 0.7rem;
        display: flex;
        justify-content: center;
        color: #fff;
        margin: 0;
        vertical-align: bottom;
        margin-right: 0.5rem;
        font-size: 0.25rem;
      }
      // tab选中
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #139bf6;
          font-size: 0.4rem;
        }
      }
      .ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: none;
      }
      .ant-tabs-ink-bar {
        width: 0 !important;
        height: 0;
      }

      // 副本内容
      .single_currency_mining,
      .LP_mining {
        position: relative;
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;
        min-height: 8rem;
        margin-bottom: -100px;
        .swiper-container {
          height: 100%;
          position: static;
          .swiper-wrapper {
            width: 10rem;
          }
          .single_item {
            width: 3rem;
            height: 3.6rem;
            color: #fff;
            padding: 0.1rem;
            background: url("../../assets2/mining/mining_box_bg.png") no-repeat;
            background-size: 100% 100%;
            .single_item_box {
              width: 100%;
              height: 100%;
              padding: 0.1rem 0.3rem;
              padding-bottom: 0.2rem;
              .single_title {
                width: 70px;
                height: 70px;
                margin: 0.2rem auto;
                > img {
                  width: 100%;
                  height: 100%;
                }
              }
              .single_con {
                text-align: center;
                font-size: 12px;
              }
              .single_gwc {
                text-align: center;
                font-size: 12px;
              }
              .xuanze {
                width: 100%;
                height: 0.7rem;
                margin-top: 0.2rem;
                line-height: 0.7rem;
                text-align: center;
                font-size: 12px;
                color: #fff;
                background: url("../../assets2/mining/mini_xz.png") no-repeat;
                background-size: 100% 100%;
                &:hover {
                  background-color: #0077c7;
                  border: 1px solid #fff;
                }
              }
              .single_info {
                margin-top: 0.2rem;
                p {
                  margin: 0;
                  font-size: 12px;
                  span {
                    float: right;
                    color: #bae4ff;
                  }
                }
              }
            }
          }
          // 上一页按钮
          .swiper-button-prev {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            background: url("../../assets1/game/but-prev.png") no-repeat center;
            background-size: 100% 100%;
          }
          .swiper-button-next {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            background: url("../../assets1/game/but-next.png") no-repeat center;
            background-size: 100% 100%;
          }
          .swiper-button-prev:after,
          .swiper-button-next:after {
            content: none;
          }
          .swiper-button-disabled {
            opacity: 1;
          }
        }
        .single_currency_mining_brief {
          width: 100%;
          background: url("../../assets-en/rules_bg.png") no-repeat;
          background-size: 100% 100%;
          margin-top: 0.3rem;
          font-size: 12px;
          letter-spacing: 1px;
          color: #fefefe;
          padding: 1rem 0.5rem;
          position: relative;
          .mining_brief_title {
            position: absolute;
            top: -0.2rem;
            left: -0.1rem;
            width: 3.2rem;
            height: 1.1rem;
          }
        }
      }
      .single_currency_mining {
        .single_item {
          &:nth-child(1) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/usdt.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(2) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/btc.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(3) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/eth.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(4) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/fil.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(5) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/ht.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(6) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/husd.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(7) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/link.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(8) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/ltc.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(9) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/dot.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(10) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/bch.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &:nth-child(11) {
            .single_item_box {
              .single_title {
                background: url("../../assets2/mining/gwc.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .miningbg_con {
    .single_currency_mining,
    .LP_mining {
      .single_item {
        height: 3.5rem !important;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .mining {
    background-color: #080c4d;
  }
  .miningbg_bg {
    display: none !important;
  }
  .miningbg_con {
    width: 100% !important;
    .mining_tab {
      .ant-tabs-nav {
        padding: 0 !important;
      }

      .single_currency_mining,
      .LP_mining {
        position: absolute;
        top: -150px;
        display: block !important;
        padding: 0 !important;

        .swiper-wrapper {
          width: 100% !important;
          flex-direction: inherit !important;
          justify-content: space-between;
          .single_item {
            width: 48% !important;
            height: auto !important;
            margin-top: 0.2rem !important;
            margin-right: 0 !important;
            padding: 0.1rem !important;
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          display: none !important;
        }
      }
    }
  }
}

@primary-color:  #961315;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);
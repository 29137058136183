.game {
  width: 100%;
  position: absolute;
  top: 0;
  padding-top: 0.8rem;
  min-height: 100vh;
  .game_top {
    position: absolute;
    width: 100%;
    height: 150px;
    z-index: 1;
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-between;
    .top_img {
      width: 2rem;
      padding-top: 0.3rem;
      > img {
        width: 1.5rem;
      }
    }
    .tab_title {
      width: 3.5rem;
      display: flex;
      padding-top: 0.3rem;
      .tab_lp {
        width: 1.6rem;
        height: 0.8rem;
        margin-right: 0.1rem;
        display: flex;
        align-items: center;
        > img {
          width: 100%;
          height: 100%;
        }
        .tab_img {
          height: 60%;
        }
      }
      .tab_sc {
        display: flex;
        align-items: center;
        width: 1.6rem;
        height: 0.8rem;
        > img {
          width: 100%;
          height: 100%;
        }
        .tab_img {
          height: 60%;
        }
      }
    }
  }
  // 背景
  .game_bg {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    opacity: 0.95;
  }
  // 内容
  .game_con {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 0.5rem;
    background-image: linear-gradient(#041663, #671789);
    
    .game_con_tab {
      position: relative;
      .ant-tabs-nav {
        padding: 0 1.5rem;
        margin-bottom: 0 !important;
      }
      // tab未选中
      .ant-tabs-tab {
        height: 0.7rem;
        display: flex;
        justify-content: center;
        color: #fff;
        margin: 0;
        vertical-align: bottom;
        margin-right: 0.5rem;
        font-size: 0.25rem;
      }
      // tab选中
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #139bf6;
          font-size: 0.4rem;
        }
      }
      .ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: none;
      }
      .ant-tabs-ink-bar {
        width: 0 !important;
        height: 0;
      }
      .LP_copy{
        .swiper-slide{
          margin: 4.59rem auto .5rem auto !important;
        }
      }
      // 副本内容
      .single_currency_copy,
      .LP_copy {
        position: relative;
        min-height: 4rem;
        // padding-top: 0.3rem;

        .swiper-container {
          min-height: 100%;
          position: static;
          .swiper-wrapper {
            width: 100%;
          }
          .swiper-slide {
            width: 100% !important;
            height: 3rem;
            margin: .8rem auto .5rem auto;
            color: #fff;
            padding-bottom: 0.7rem;
            position: relative;
            background: url("../../assets2/game/js.png");
            background-size: 100% 100%;
            .borderTopBox{
              position: absolute;
              bottom: 3.995rem;
              left: -40px;
              display: flex;
              justify-content: center;
              .border_topes{
                width: 6.65rem;
                padding: .3rem;
                border: 2px #fff solid;
                border-radius: 4px;
                .b_img{
                  position: absolute;
                  top: -1.35rem;
                  left: 2.65rem;
                  width: 1.95rem;
                  height: 1.55rem;
                }
                .b_img_en{
                  position: absolute;
                  top: -1.35rem;
                  left: 2.55rem;
                  width: 1.95rem;
                  height: 1.55rem;
                }
              }
            }
            .currency_title {
              position: absolute;
              top: -0.3rem;
              right: -0.4rem;
              text-align: right;
              width: 1.5rem;
              height: 0.9rem;
              line-height: 0.2rem;
              text-align: center;
              font-weight: 700;
              font-size: 18px;
              color: #332c2b;
              background: url("../../assets2/game/right_bubble.png") no-repeat;
              background-size: 100% 100%;
              padding-top: 0.2rem;
            }

            .currency_img {
              position: absolute;
              top: -0.5rem;
              left: -0.6rem;
              width: 1.5rem;
              height: 1.5rem;
              // background: url("../../assets2/game/eth.png") no-repeat;
              background-size: 100% 100%;
            }
            .currency_info {
              padding: 0 1rem;
              padding-top: 0.3rem;
              color: #fefefe;
              p {
                margin: 0;
                font-size: 12px;
                line-height: 0.4rem;
                span {
                  float: right;
                }
              }
            }
            .currency_determine {
              margin: 0 auto;
              margin-top: 0.2rem;
              width: 2rem;
              height: 0.4rem;
              line-height: 0.4rem;
              border-radius: 0.2rem;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              cursor: default;
              background: url("../../assets2/game/btn.png") no-repeat;
              background-size: 100% 100%;
              &:hover,
              &:focus {
                background: url("../../assets2/game/btn_ac.png") no-repeat;
                background-size: 100% 100%;
              }
              // img {
              //   width: 10px;
              //   height: 10px;
              //   margin-right: 5px;
              //   margin-top: 3px;
              // }
            }
            .currency_income {
              margin: 0 auto;
              margin-top: 0.1rem;
              width: 2rem;
              height: 0.4rem;
              text-align: center;
              line-height: 0.4rem;
              border-radius: 0.2rem;
              background: url("../../assets2/game/btn.png") no-repeat;
              background-size: 100% 100%;
              color: #fff;
              font-size: 12px;
              cursor: default;
              &:hover {
                background: url("../../assets2/game/btn_ac.png") no-repeat;
                background-size: 100% 100%;
              }
            }
            &:nth-child(2n) {
              .currency_title {
                top: -0.3rem;
                left: -0.4rem;
                background: url("../../assets2/game/left_bubble.png") no-repeat;
                background-size: 100% 100%;
              }
              .currency_img {
                top: -0.5rem;
                left: auto;
                right: -0.6rem;
              }
            }
          }
          .swiper-slide_kaijianges{
            margin-top: 5.55rem !important;
          }
          .swiper-slide_kaijiang,.swiper-slide_kaijianges,.one_swiper_slide_kaijiang {
            // margin-top:13.15rem;
            margin-top: 4.889rem;
            position: relative;
            background: url("../../assets2/game/kj.png") no-repeat;
            background-size: 100% 100%;
            .top_bg,.top_bg_en{
              position: absolute;
              top: -13.15rem;
              left: -.856rem;
              background: url('../../assets1/game_new/top_bg.png') 0 -12px no-repeat;
              background-size: 87% 85%;
              width: 150%;
              height: 13rem;
              .top{
                position: absolute;
                top: 1.48rem;
                left: .995rem;
                // line-height: .2rem;
              }
              .bottomesBox{
                display: flex;
                justify-content: center;
                position: absolute;
                top: 9.1rem;
                left: 4px;
                // margin-bottom: 20px !important;
              }
              .bottomes{
                width: 6.65rem;
                padding: .3rem;
                border: 2px #fff solid;
                border-radius: 4px;
                
              }
              .bottomes::after{
                content: '';
                background: url('../../assets1/game_new/tou1.png') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                left: 2.34rem;
                top: -1.35rem;
                width: 2.25rem;
                height: 1.55rem;
              }
            }
            .top_bg_en{
              top: -14.05rem;
            }
            .currency_title {
              span {
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 1.4rem;
                display: inline-block;
              }
            }
            .currency_determine {
              img {
                width: 13px;
                height: 15px;
                margin-top: 0;
              }
            }
          }
          .one_swiper_slide_kaijiang{
            margin-top:13.15rem ;
            background: url("../../assets2/game/js.png") ;
            background-size: 100% 100% ;
          }
          .one_show{
            margin-top:13.15rem ;
            background: url("../../assets2/game/kj.png") ;
            background-size: 100% 100% ;
          }
          .swiper_test{
            margin-top: 4.889rem;
          }
          .swiper-slide_canyu {
            margin-top: 4.889rem;
            .currency_determine {
              background: url("../../assets/hong_qd.png") no-repeat;
              background-size: 100% 100%;
              img {
                width: 13px;
                height: 15px;
                margin-top: 0;
              }
            }
          }

          // 上一页按钮
          .swiper-button-prev {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            background: url("../../assets1/game/but-prev.png") no-repeat center;
            background-size: 100% 100%;
          }
          .swiper-button-next {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            background: url("../../assets1/game/but-next.png") no-repeat center;
            background-size: 100% 100%;
          }
          .swiper-button-prev:after,
          .swiper-button-next:after {
            content: none;
          }
          .swiper-button-disabled {
            opacity: 1;
          }
        }
      }
      .single_currency_copy {
        .swiper-slide {
          &:nth-child(1) {
            .currency_img {
              height: 1.6rem;
              background: url("../../assets2/game/eth.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(2) {
            .currency_img {
              height: 1.6rem;
              background: url("../../assets2/game/btc.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(3) {
            .currency_img {
              background: url("../../assets2/game/avatar_treen.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(4) {
            .currency_img {
              background: url("../../assets2/game/usdt.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(5) {
            .currency_img {
              height: 1.7rem;
              background: url("../../assets2/game/gwc.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(6) {
            .currency_img {
              background: url("../../assets2/game/fil.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(7) {
            .currency_img {
              height: 1.7rem;
              background: url("../../assets2/game/gwc.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
      .LP_copy {
        .swiper-slide {
          .currency_title {
            font-size: 0.12rem !important;
            padding: 0.2rem 0.15rem 0 0.15rem !important;
          }
          .currency_img {
            top: -0.8rem !important;
          }
          &:nth-child(1) {
            .currency_img {
              height: 1.8rem;
              width: 1.688rem;
              background: url("../../assets2/game/eth.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(2) {
            .currency_img {
              height: 1.8rem;
              // width: 1.588rem;
              background: url("../../assets2/game/btc.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(3) {
            .currency_img {
              height: 1.9rem;
              width: 1.788rem;
              background: url("../../assets2/game/avatar_treen.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(4) {
            .currency_img {
              height: 1.8rem;
              width: 1.6rem;
              background: url("../../assets2/game/usdt.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(5) {
            .currency_img {
              height: 1.8rem;
              width: 1.3rem;
              background: url("../../assets2/game/ht-usdtimg.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(6) {
            .currency_img {
              width: 1.3rem;
              background: url("../../assets2/game/ht-usdt.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-child(7) {
            .currency_img {
              width: 1.3rem;
              background: url("../../assets2/game/mdx-usdt.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
    //后加入的样式
    .explore_box{
      padding: 0 0.4rem;
      background: url('../../assets1/game_new/bd.png') no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 6.65rem;
      margin-top: 20px;
      position: relative;
      .top{
        position: absolute;
        right: .35rem;
        top: .65rem;
        color: #fefefe;
        font-weight: 400;
        font-size: .14rem;
      }
      .top_en{
        position: absolute;
        right: -.01rem;
        top: -.35rem;
        color: #fefefe;
        width: 4.35rem;
        font-size: .1rem;
      }
      .bottom{
        position: absolute;
        left: .35rem;
        bottom: .597rem;
        color: #fefefe;
        font-weight: 400;
        font-size: .14rem;
      }
      .bottom_en{
        width: 4.35rem;
        position: absolute;
        left: .35rem;
        bottom: .85rem;
        color: #fefefe;
        font-size: .1rem;
      }
    }
    // 游戏规则
    .game_rule {
      padding: 0 0.4rem;
      .game_rule_brief {
        margin-top: 0.2rem;
        color: #fefefe;
        background: url("../../assets-en/rules_bg.png");
        background-size: 100% 100%;
        padding: 1rem 0.4rem;
        font-size: 12px;
        position: relative;
        p {
          margin: 0;
          letter-spacing: 1px;
          display: flex;
        }

        .game_rule_title {
          width: 3.4rem;
          height: 1.1rem;
          position: absolute;
          top: -0.2rem;
          left: -0.1rem;
        }
      }
    }
  }
}
.new_avatar{
  position: absolute;
  top: -.785rem;
  left: 1.25rem;
  img{
    width: .6rem;
    height: .8rem;
  }
}

@media screen and (max-width: 750px) {
  .game_con {
    width: 100% !important;
    .ant-tabs-nav {
      padding: 0 !important;
      height: 150px;
      background: url("../../assets2/game/game_top.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .ant-tabs-nav-wrap {
        position: absolute;
        right: 0;
        padding-top: 0.3rem;
      }
    }
    // tab未选中
    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      color: #fff;
      margin: 0;
      vertical-align: bottom;
    }
    // tab选中
    .ant-tabs-tab-active {
      font-weight: 700;
    }
    .single_currency_copy,
    .LP_copy {
      height: auto !important;
      .swiper-wrapper {
        width: 100% !important;
        position: relative;
        padding-bottom: 0.4rem !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 0.8rem;

        .swiper-slide_canyu {
          .currency_btn {
            .currency_determine {
              width: 135px !important;
            }
            .currency_income {
              width: 135px !important;
            }
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: none !important;
      }
    }
  }
}
.kill-gif{
  .bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background: #000;
    opacity: .75;
  }
  .gif{
    width: 100%;
    max-width: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1001;
  }
}


.mb10 {
  margin-bottom: 20px;
}
@primary-color:  #961315;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);
.topbar,.topbarhome {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 0.8rem;
  background-color: #041763;
  padding: 0 1.5rem;
  color: #fff;
  z-index: 99999;

  .top_title {
    display: flex;
    align-items: center;
    img {
      width: 0.3rem;
      margin-right: 0.1rem;
    }
    .top_title_text {
      width: 50px;
      font-size: 0.18px;
      line-height: 14px;
      font-weight: bold;
    }
  }
  .top_link {
    margin-left: 1rem;
    .menu_list {
      background-color: transparent;
      border-bottom: none;
      li {
        height: 0.8rem;
        padding: 0 0.4rem;
        line-height: 0.8rem;
        color: #fff;
      }
      .ant-menu-item-active {
        color: #fff;
        border-bottom: none;
      }
      .ant-menu-item:hover::after {
        border-bottom: none;
      }
      .ant-menu-item-selected {
        color: #139bf6;
        span {
          border-bottom: 1px solid #139bf6;
        }
      }
    }
  }
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: none;
    transition: none;
  }

  .top_personal {
    display: flex;
    align-items: center;
    .address {
      height: 0.4rem;
      position: relative;
      .personal_address {
        padding-left: 0.1rem;
        line-height: 0.4rem;
        background-color: #212121;
        border-radius: 0.1rem;
        span {
          margin-left: 10px;
          padding: 0 0.1rem;
          line-height: 0.4rem;
          height: 100%;
          float: right;
          background-color: #139bf6;
          border-radius: 0.1rem;
        }
      }
    }
    .weiaddress {
      padding: 0.1rem 0.2rem;
      background-color: #139bf6;
      border-radius: 0.2rem;
      cursor: pointer;
    }

    .personal_img {
      margin-left: 0.3rem;
      img {
        width: 0.3rem;
      }
      > span {
        font-size: 12px;
        color: #fff;
        margin-left: 0.1rem;
      }
    }
  }
  .top_menu {
    display: none;
  }
}
.topbarhome{
  background-image: url('../../assets2/top/top_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
// 连接钱包弹窗
.ant-modal-root,
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 10000;
}
.ant-modal-content {
  box-shadow: none !important;
}
.ant-modal-body {
  padding: 0;
}
.ant-modal-content {
  background-color: transparent;
}
.wallet_modal_wei {
  padding: 0 0.5rem;
}
.connect_wallet_top {
  color: #fff;
  overflow: hidden;
  .wallet_top {
    height: 0.7rem;
    position: relative;
    .wallet_title {
      line-height: 0.7rem;
      text-align: center;
      color: #fff;
      font-size: 22px;
    }
    .wallet_gb {
      position: absolute;
      right: 0rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
    }
  }
  .wallet_btn {
    padding: 0.3rem 0.5rem;
    margin-top: 0.2rem;
    width: 100%;
    background: url("../../assets2/game/info_box_bg.png") no-repeat;
    background-size: 100% 100%;
    .wallet_lianjie {
      display: flex;
      justify-content: space-between;
      margin-top: 0.2rem;
      .wallet_metamask,
      .wallet_conncet {
        width: 47%;
        padding: 0.2rem 0;
        border-radius: 0.2rem;
        background-color: #0662a5;
        .wallet_metamask_img {
          text-align: center;
          width: 100%;
          // .name_bg {
          //   width: 0.65rem;
          //   height: 0.65rem;
          // }
          .metamask_img {
            width: 0.8rem;
          }
        }
        .lianjie_title {
          text-align: center;
          color: #fff;
        }
        .wallet_metamask_btn {
          width: 100%;
          height: 0.7rem;
          line-height: 0.7rem;
          text-align: center;
          margin-top: 0.2rem;
          background: url("../../assets2/mining/mini_xz.png");
          background-size: 100% 100%;
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .quxiao {
      margin: 0.2rem auto;
      width: 2rem;
      height: 0.7rem;
      line-height: 0.65rem;
      text-align: center;
      background: url("../../assets2/game/info_btn.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 12px;
    }
  }
}

.qrcode_tc {
  z-index: 10001;
  .tQRcode {
    background-color: #2a2b31;
    overflow: hidden;
    .QRcode_title {
      color: #fff;
      margin: 0.1rem 0;
      img {
        width: 0.3rem;
        margin: 0 0.2rem;
      }
      .qrGuanbi {
        position: absolute;
        top: 0;
        right: 0;
        width: 0.2rem;
        height: 0.2rem;
        line-height: 0.2rem;
        text-align: center;
        font-size: 12px;
        border-radius: 50%;
        display: inline-block;
        background-color: #fff;
        color: #000;
        cursor: pointer;
      }
    }

    .QRcode_con {
      width: 4rem;
      height: 4rem;
      background-color: #fff;
      border-radius: 10px;
      padding: 0 0.4rem;
      .QRcode_con_title {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
      }
      .QRcode_con_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
// 未连接的钱包弹窗
.connect_wallet_top {
  .wallet_top {
    width: 100%;
    position: relative;
    .wallet_title {
      position: absolute;
      left: 50%;
      bottom: -0.4rem;
      transform: translateX(-50%);
      width: 1.8rem;
      height: 0.9rem;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .wallet_btn {
      .wallet_lianjie {
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        > div {
          width: 45% !important;
          padding: 0.4rem 0.2rem !important;
          .wallet_metamask_img {
            padding: 0 !important;
          }
        }
      }
    }
  }
}

// 已连接钱包弹窗
.connected_wallet_yi {
  width: 5rem;
  height: 3.5rem;
  margin-top: 0.3rem;
  position: relative;
  padding: 0 0.6rem;
  // .connected_top {
  //   position: relative;
  //   color: #fff;
  //   .connected_title {
  //     height: 0.7rem;
  //     text-align: center;
  //     line-height: 0.7rem;
  //     font-size: 22px;
  //   }
  //   .connected_gb {
  //     position: absolute;
  //     right: 0;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     font-size: 20px;
  //   }
  // }
  .connected_btn {
    margin-top: 0.2rem;
    width: 100%;
    background: url("../../assets2/game/info_box_bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 0.2rem 0.3rem;
    .connected_con {
      width: 100%;
      height: 100%;
      background-color: #0d7fca;
      border-radius: 0.1rem;
      padding: 0.3rem 0.2rem;
      .con_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 15px;
        > span {
          display: inline-block;
          background: url("../../assets2/game/info_btn.png") no-repeat;
          background-size: 100% 100%;
          font-size: 12px;
          height: 0.7rem;
          width: 1.2rem;
          line-height: 0.7rem;
          text-align: center;
        }
      }
      .con_address {
        height: 1rem;
        display: flex;
        align-items: center;
        .con_icon {
          width: 0.15rem;
          height: 0.15rem;
          background-color: #fff;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        > span {
          margin-left: 0.1rem;
          font-size: 16px;
          color: #ffffff;
        }
      }
      .con_link {
        color: #fff;
        display: flex;
        align-items: center;
        .link_copy {
          margin-right: 0.2rem;
          img {
            width: 0.15rem;
            height: 0.15rem;
            margin-right: 0.1rem;
          }
          > span {
            font-size: 13px;
          }
        }
        .tiaozhuan {
          display: inline-block;
          color: #fff;

          img {
            width: 0.2rem;
            height: 0.15rem;
            margin-right: 0.1rem;
          }
          > span {
            font-size: 13px;
          }
        }
      }
    }
  }
}
.ant-message {
  z-index: 10010;
}
// .ant-drawer-mask {
//   background: url("../../assets2/top/drawerbg.png") no-repeat;
//   background-size: 100% 100%;
// }
.ant-radio-wrapper {
  color: #fff !important;
}
.ant-radio-inner {
  border-color: #671789 !important;
}
.ant-radio-checked::after {
  border: 1px solid #671789 !important;
}
.ant-radio-inner::after {
  background-color: #671789 !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
}
.lang_modal {
  background: url("../../assets2/lang_bg.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 0.1rem;
  padding-bottom: 0;
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-close {
    right: 10px;
    .ant-modal-close-icon {
      color: #fff;
    }
  }
}
.ant-modal-centered {
  padding: 0 0.2rem;
}

@media screen and (max-width: 750px) {
  .topbarhome,.topbar {
    padding: 0.2rem;
  }
  .top_title {
    div {
      font-size: 12px;
    }
  }
  .top_link {
    display: none;
  }
  .top_personal {
    display: none !important;
  }
  .top_menu {
    display: block !important;
    width: 0.4rem;
    height: 0.4rem;
  }
  .tanchu {
    z-index: 10000;
    .right_link {
      background: url("../../assets2/top/nav_bg.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 0.8rem;
      padding-top: 0.2rem;
      padding-bottom: 2rem;
      a {
        width: 100%;
        line-height: 1rem;
        text-align: center;
        display: block;
        color: #fff;
        padding-left: 0.3rem;
      }
      .audit {
        padding-left: 0;
      }
      .key_active {
        width: 1.8rem;
        height: 0.7rem;
      }
      .address {
        margin-top: 1rem;
        position: relative;
        .personal_address {
          // margin-top: 0.2rem;
          // padding-left: 0.1rem;
          // line-height: 0.4rem;
          // background-color: #212121;
          // border-radius: 0.1rem;
          // padding-bottom: 0.2rem;
          span {
            position: absolute;
            left: 0;
            bottom: -0.2rem;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            padding: 0 0.1rem;
            background-color: #139bf6;
            border-radius: 0.1rem;
          }
        }
      }
      .weiaddress,
      .personal_address {
        width: 2.2rem;
        height: 0.5rem;
        margin: 0 auto;
        margin-top: 0.5rem;
        background: url("../../assets2/top/weiaddress.png") no-repeat;
        background-size: 100% 100%;
        color: #fff;
        padding: 0 0.1rem;
        font-size: 12px;
        border-radius: 0.2rem;
        cursor: pointer;
        line-height: 0.5rem;
        text-align: center;
      }
      .personal_img {
        margin-top: 0.3rem;
        text-align: center;
        img {
          width: 0.4rem;
          height: 0.4rem;
        }
        > span {
          font-size: 14px;
          color: #fff;
          margin-left: 0.1rem;
        }
      }
    }
  }

  .ant-modal-content {
  }
  .connect_wallet_top {
    width: 100% !important;
  }
  .qrcode_tc {
    z-index: 10001;
    .tQRcode {
      background-color: #2a2b31;
      overflow: hidden;
      .QRcode_title {
        color: #fff;
        margin: 0.1rem 0;
        img {
          width: 0.3rem;
          margin: 0 0.2rem;
        }
        .qrGuanbi {
          position: absolute;
          top: 0;
          right: 0;
          width: 0.4rem;
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          font-size: 12px;
          border-radius: 50%;
          display: inline-block;
          background-color: #fff;
          color: #000;
          cursor: pointer;
        }
      }

      .QRcode_con {
        width: 100%;
        height: auto;
        background-color: #fff;
        border-radius: 10px;
        padding: 0.2rem 0.4rem;
        .QRcode_con_title {
          height: 0.5rem;
          line-height: 0.5rem;
          text-align: center;
        }
        .QRcode_con_img {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .connected_wallet_yi {
    width: 100%;
    height: auto;
    /* .connected_top {
      .connected_title {
        width: 100%;
        height: 0.8rem;
        line-height: 0.7rem;
        top: -0.3rem;
      }
    } */
    .connected_con {
      height: auto;
      .con_address {
        height: 0.8rem;
        .con_icon {
          width: 0.3rem;
          height: 0.3rem;
        }
      }
      .con_link {
        .link_copy {
          img {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
        .tiaozhuan {
          img {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
      }
    }
  }
}

.ant-drawer-body {
  padding: 0;
  border: none;
  background-color: transparent;
}
.ant-drawer-content {
  background-color: transparent;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

// .ant-modal-mask {
//   background: url("../../assets2/game/moadle_bg.png") no-repeat;
//   background-size: 100% 100%;
// }

@primary-color:  #961315;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);